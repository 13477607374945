<template>
  <v-row :class="rowClass" class="repeatable-row" wrap>
    <form-input
      v-for="(input, inputIndex) in inputArr"
      :key="'col-' + inputIndex"
      :value="rowData[input.label]"
      :input="input"
      :index="inputIndex"
      :has-error="input.label in uniqueErrors && uniqueErrors[input.label]"
      :error-messages="input.label in uniqueErrors && uniqueErrors[input.label] ? [input.label + ' must be unique within ' + label + (label.substring(label.length - 1) === 's' ? '' : ' entries')] : []"
      @update="updateEntry">
      <template v-if="showRemoveButton(inputIndex, input) && (input.input === 'checkbox' || input.input === 'radio' || input.input === 'switch' || input.input === 'slider')" v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon color="red" @click="$emit('remove')" v-on="on">fas fa-times-circle</v-icon>
          </template>
          <span>Remove this entry</span>
        </v-tooltip>
      </template>
      <template v-else-if="showRemoveButton(inputIndex, input)" v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon color="red" @click="$emit('remove')" v-on="on">fas fa-times-circle</v-icon>
          </template>
          <span>Remove this entry</span>
        </v-tooltip>
      </template>
    </form-input>
  </v-row>
</template>
<script>
import { ref, onMounted, reactive, computed, watch } from '@vue/composition-api'
import { checkIfs } from '../../inputs/checkOp'
export default {
  components: {
    FormInput: () => import('../../FormInput')
  },
  props: {
    label: {
      type: String,
      required: true
    },
    inputs: {
      type: Array,
      required: true
    },
    entryIndex: {
      type: Number,
      required: true
    },
    rowData: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      default: true
    },
    uniqueErrors: { // Fields that are not unique within the repeatable block; these should have an error
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const myData = reactive({})
    const inputArr = ref([])
    const dependents = reactive({})

    function updateEntry ({ field, value }) {
      myData[field] = value
      emit('update', { index: props.entryIndex, field, value })

      // Logic for hiding/showing fields based on the value that was just updated
      if (field in dependents) {
        for (let i = 0; i < dependents[field].length; i++) {
          const subField = dependents[field][i]
          for (let j = 0; j < props.inputs.length; j++) {
            if (subField === props.inputs[j].label) {
              // Re-evaluate all of the "ifs" associated with this dependent field
              let visible = checkIfs(props.inputs[j].ifs, myData)
              if (inputArr.value[j].visible !== visible) {
                inputArr.value.splice(j, 1, { ...inputArr.value[j], visible })
              }
            }
          }
        }
      }
    }

    watch(() => props.rowData, () => {
      for (const l in props.rowData) {
        if (!(l in myData) || myData[l] !== props.rowData[l]) myData[l] = props.rowData[l]
      }
    })

    const rowClass = computed(() => {
      let arr = []
      // if (props.entryIndex === props.blockData.length - 1) arr.push('last-row')
      if (props.removable) arr.push('removable')
      return arr.join(' ')
    })

    onMounted(() => {
      for (const input of props.inputs) {
        let temp = { ...input, visible: true }
        dependents[temp.label] = []
        if ('ifs' in input) {
          temp.visible = checkIfs(input.ifs, props.rowData)
          for (const { field } of input.ifs) {
            dependents[field].push(temp.label)
          }
        }
        inputArr.value.push(temp)
      }
    })

    function showRemoveButton (inputIndex, input) {
      let isLast = false
      for (let i = inputArr.value.length - 1; i >= 0; i--) {
        if (inputArr.value[i].visible) {
          if (i === inputIndex) isLast = true
          break
        }
      }
      return !(!props.removable || !isLast || ('_removable' in input && !input._removable))
    }

    return {
      myData,
      inputArr,
      dependents,
      updateEntry,
      rowClass,
      showRemoveButton
    }
  }
}
</script>
